<template>
    <label @click="input.click()">
        <template name="button">
            <!-- <i slot="header" slot-scope="scope" class="iconfont iconadd-circle"></i> -->
            <i class="el-icon-circle-plus-outline" v-if="isshow" style="font-size: 25px;cursor:pointer"></i>
        </template>
    </label>
</template>

<script>

export default {
    props: {
        fileCodeParam: {
            type: String,
            default: '',
        },
        isshow: {
            default: true,
        }
    },
  components: {},
  data() {
    return {

    };
  },
  watch: {

  },
    mounted() {
        this.input = document.createElement('input');
        this.input.setAttribute("type", "file");
        this.input.setAttribute("multiple", "multiple");
        this.input.addEventListener('change', e => {

            var files = e.target.files;

            var arr = [];

            for (let i = 0; i < files.length; i++) {
                var form = new FormData();
                form.append("file", files[i]);
                form.append("type", this.fileCodeParam);
                this.$api.common.uploadFile(form).then(res => {

                    //setTimeout(()=>{
                        arr[0].loading = false;
                        arr[0].fileUrl = res.data.filePath;
                        arr[0].attachmentPath = res.data.filePath;
                    //},10000)

                }).catch(err => {
                    this.input.value = '';
                });
                arr[0] = {
                    attachmentName:files[i].name,
                    attachmentType:files[i].name.getSuffix(),
                    loading:true,
                }

            }
            this.$emit("upload",arr);
        });
    },
  methods:{

  }
};
</script>

<style lang="scss" scoped>

</style>
